import React from 'react';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

const MarketInfoWapper = styled.div`
  padding: 16px;
  background: #fff;
  border-bottom: 4px solid #f1f3f5;
`;

const PbInfo = styled.div`
  padding-bottom: 8px;

  & > h1 {
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
  }
  & > p {
    font-size: 15px;
    line-height: 19px;
    color: #343a40;
    padding-top: 8px;
  }
`;

const Grade = styled.div`
  font-size: 15px;
  color: #495057;
  padding-top: 4px;

  & > img {
    height: 16px;
  }

  & > span {
    vertical-align: text-top;
  }
`;

const PbPrice = styled.ul`
  padding-top: 8px;
`;

const Line = styled.li`
  font-size: 12px;
  line-height: 15px;
  color: #adb5bd;
  text-decoration: line-through;
  padding-bottom: 2px;
`;

const Price = styled.li`
  font-size: 19px;
  font-weight: 800;
  color: #212529;
  line-height: 24px;
`;

const DeliveryInfo = styled.ul`
  border-top: 1px solid #e9ecef;
  padding-top: 8px;

  & > li {
    font-size: 12px;
    color: #495057;
    line-height: 15px;
  }
  & > li:not(:last-of-type) {
    padding-bottom: 4px;
  }
`;

const SkeletonMarketInfo = () => {
  return (
    <MarketInfoWapper>
      <PbInfo>
        <h1>
          <Skeleton />
        </h1>
        <Grade>
          <Skeleton />
        </Grade>
        <p>
          <Skeleton />
        </p>
        <PbPrice>
          <Line></Line>
          <Price>
            <Skeleton />
          </Price>
        </PbPrice>
      </PbInfo>
      <DeliveryInfo>
        <li>
          <Skeleton />
        </li>
        <li>
          <Skeleton />
        </li>
        <li>
          <Skeleton />
        </li>
      </DeliveryInfo>
    </MarketInfoWapper>
  );
};

export default SkeletonMarketInfo;
