import React from 'react';
import { CompanyDetail } from '../../types/reservation';
import styled from '@emotion/styled';
import MenuItem from './MenuItem';
import { NoItem } from './styles';

const Base = styled.div`
  width: 100%;
`;

const Menu = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 5px solid #f1f3f5;
`;

const Top = styled.div`
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  font-weight: 700;
  font-size: 16px;
  color: #212529;
`;
interface Props {
  data: CompanyDetail;
}
const DetailMenu = ({ data }: Props) => {
  return (
    <Base>
      <Top>예약상품</Top>
      {data.normalProducts.length < 1 && (
        <NoItem>아직 등록된 상품이 없습니다.</NoItem>
      )}
      <Menu>
        {data.normalProducts.map((v) => (
          <MenuItem
            key={v.id}
            image={v.imgPath}
            title={v.title}
            description={v.description}
            discount={v.discount}
            price={v.price}
          />
        ))}
      </Menu>
      {data.generals.length > 0 && (
        <>
          <Top>현장 줄서기 레저(예약불가)</Top>
          <Menu>
            {data.generals.map((v) => (
              <MenuItem
                key={v.id}
                image={v.imgPath}
                title={`${v.boatName}|${v.leisureName}`}
                description={''}
                discount={0}
                price={v.price}
                isGeneral={true}
              />
            ))}
          </Menu>
        </>
      )}
    </Base>
  );
};

export default DetailMenu;
