import axios, { AxiosResponse } from 'axios';
import { API_DOMAIN } from '../../utils/util';
import { useQuery, UseQueryResult } from 'react-query';
import { TabListResponse } from '../../types/market';
import { ProductListResponse } from '../../types/market';
import { MarketDetailResponse } from '../../types/market';
import { MainBannerResponse } from '../../types/market';

const getTabListApi = () => {
  return axios.get(API_DOMAIN + '/v1/leppa-market/main/category');
};

export const useTabListQuery = (): UseQueryResult<
  AxiosResponse<TabListResponse>,
  Error
> =>
  useQuery(['useTabListQuery'], () => getTabListApi(), {
    retry: 3,
    keepPreviousData: true,
  });

const getProductListApi = (select: number) => {
  return axios.get(API_DOMAIN + `/v1/leppa-market/items?categoryId=${select}`);
};

export const useProductListQuery = (
  select?: number,
): UseQueryResult<AxiosResponse<ProductListResponse>, Error> =>
  useQuery(
    ['useProductListQuery'],
    () => {
      if (select) {
        return getProductListApi(select);
      }
    },
    {
      retry: 3,
    },
  );

const getMarketDetailApi = (itemId: string) => {
  return axios.get(API_DOMAIN + `/v1/leppa-market/items/${itemId}`);
};

export const useMarketDetailQuery = (
  itemId?: string,
): UseQueryResult<AxiosResponse<MarketDetailResponse>, Error> =>
  useQuery(
    ['useMarketDetailQuery'],
    () => {
      if (itemId) {
        return getMarketDetailApi(itemId);
      }
    },
    {
      retry: 3,
    },
  );

const getMainBannerApi = () => {
  return axios.get(API_DOMAIN + '/v1/leppa-market/top');
};

export const useMainBannerQuery = (): UseQueryResult<
  AxiosResponse<MainBannerResponse>,
  Error
> =>
  useQuery(['useMainBannerQuery'], () => getMainBannerApi(), {
    retry: 3,
    keepPreviousData: true,
  });
