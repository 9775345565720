import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import BottomMenu from '../components/common/BottomMenu';
import Logo from '../components/common/Logo';
import Footer from '../components/common/Footer';
import { useMainBannerQuery, useTabListQuery } from '../hooks/market/useMarket';
import TabList from '../components/market/TabList';
import { useProductListQuery } from '../hooks/market/useMarket';
import ProductItem from '../components/market/MarketItem';
import { Header } from '../components/common/styles';
import { Slide } from 'react-slideshow-image';
import Skeleton from 'react-loading-skeleton';
import SkeletonMarketItem from '../components/market/SkeletonMarketItem';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;
const SliderWrapper = styled.div`
  height: 100vw;
  max-height: 274px;
  position: relative;

  video {
    width: 360px;
    height: 360px;
    object-fit: cover;
  }
`;
const SlideEffect = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vw;
    max-height: 270px;
  }
`;
const ProgressBar = styled.div`
  display: block;
  width: 100%;
  height: 4px;
  background-image: linear-gradient(to right, #007eca, #007eca);
  background-repeat: no-repeat;
  transition: background-size 0.4s ease-in-out;
`;
const Title = styled.div`
  display: flex;
  background: #f1f3f5;
  padding: 0 16px;

  & > div {
    font-size: 16px;
    color: #495057;
    padding: 14px 0;
    font-weight: 600;
  }
`;
const ProductList = styled.div`
  padding: 100px 0 50px;
  background: #f1f3f5;
`;
const TabMenu = styled.ul`
  display: flex;
  gap: 8px;
  font-size: 12px;
  list-style: none;
  padding: 16px;
  margin: 0;

  & > li {
    color: #495057;
    padding: 4px 8px;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 15px;
    line-height: 20px;
    cursor: pointer;
  }
  & > li.active {
    color: #f6f6f6;
    background: #404040;
  }
`;
const Product = styled.div`
  padding: 0 16px;
`;
const SkeletonLi = styled.li`
  width: 13%;
  font-size: 18px;
  padding: 0 !important;
  line-height: unset !important;
`;

const Market = () => {
  const MainBanner = useMainBannerQuery();
  const [index, setIndex] = useState(1);
  const onChange = useCallback((from: number, to: number) => {
    setIndex(to + 1);
  }, []);
  let animation: number =
    (index / Number(MainBanner.data?.data.ResultData.content.length)) * 100;

  const [select, setSelect] = useState(-1);
  const getData = (select: number) => {
    setSelect(select);
  };
  const Tab = useTabListQuery();
  const { isLoading, isError, data, refetch } = useProductListQuery(select);
  useEffect(() => {
    refetch();
  }, [select, refetch]);

  const TabLength = Number(Tab.data?.data.ResultData.content.length);

  if (isLoading || isError) {
    return (
      <Base>
        <Header>
          <Logo />
          <Title>
            <div>레빠마켓</div>
          </Title>
        </Header>
        <ProductList>
          <SliderWrapper>
            <Skeleton height={'100%'} />
          </SliderWrapper>
          <TabMenu>
            {Array.from({ length: TabLength }).map((v, i) => (
              <SkeletonLi key={i}>
                <Skeleton />
              </SkeletonLi>
            ))}
          </TabMenu>
          <Product>
            {Array.from({ length: Math.ceil(window.innerHeight / 250) }).map(
              (v, i) => (
                <SkeletonMarketItem key={i} />
              ),
            )}
          </Product>
        </ProductList>
      </Base>
    );
  }

  return (
    <Base>
      <Header>
        <Logo />
        <Title>
          <div>레빠마켓</div>
        </Title>
      </Header>
      <ProductList>
        <SliderWrapper>
          <Slide arrows={false} transitionDuration={300} onChange={onChange}>
            {MainBanner.data?.data.ResultData.content.map((b, i) => (
              <SlideEffect key={i}>
                <div
                  style={{
                    backgroundImage: `url('${b.imageUrl}')`,
                    backgroundPosition: 'center',
                  }}
                  onClick={() => {
                    if (b.referenceData !== '') {
                      let referenceData = JSON.parse(b.referenceData);
                      if (referenceData.url.startsWith('http')) {
                        window.open(referenceData.url, '_blank');
                      }
                    }
                  }}
                ></div>
              </SlideEffect>
            ))}
          </Slide>
          <ProgressBar
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              backgroundSize: `${animation}% 100%`,
            }}
          ></ProgressBar>
        </SliderWrapper>
        <TabMenu>
          {Tab.data?.data.ResultData.content.map((v) => (
            <TabList
              key={v.categoryId}
              categoryId={v.categoryId}
              categoryName={v.categoryName}
              getData={getData}
              tabNum={select}
            />
          ))}
        </TabMenu>
        <Product>
          {data?.data.ResultData.content.map((p) => (
            <ProductItem
              key={p.itemId}
              itemId={p.itemId}
              title={p.title}
              imgPath={p.imgPath}
              isSoldOut={p.isSoldOut}
              price={p.price}
              discount={p.discount}
              reviewCount={p.reviewCount}
              reviewRating={p.reviewRating}
            />
          ))}
        </Product>
      </ProductList>
      <Footer />
      <BottomMenu />
    </Base>
  );
};

export default Market;
