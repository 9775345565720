import { CompanyReview } from '../../types/reservation';
import styled from '@emotion/styled';
import React from 'react';
import ReviewItem from './ReviewItem';
import { NoItem } from './styles';

const Base = styled.div`
  width: 100%;
`;

const Top = styled.div`
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  font-weight: 700;
  font-size: 16px;
  color: #212529;
`;

const Review = styled.ol`
  padding: 0 20px;
  list-style: none;
  margin: 0;
`;

interface Props {
  review: Array<CompanyReview>;
}
const DetailReview = ({ review }: Props) => {
  return (
    <Base>
      <Top>
        <div>후기({review.length})</div>
      </Top>
      {review.length < 1 && (
        <NoItem isCenter={false}>
          아직 후기가 없어요. <br />첫 번째 후기 작성자가 되어주세요!
        </NoItem>
      )}
      {review.length > 0 && (
        <Review>
          {review.map((v, i) => (
            <ReviewItem
              key={i}
              profileImagePath={v.profileImagePath}
              name={v.name}
              regDt={v.regDate}
              reviewTxt={v.reviewTxt}
              message={v.message}
              boatName={v.boatName}
              leisureName={v.leisureName}
              title={v.title}
              images={v.images}
            />
          ))}
        </Review>
      )}
    </Base>
  );
};

export default DetailReview;
