import React from 'react';
import { CompanyDetailResponse } from '../../types/reservation';
import {
  CompanyDescription,
  CompanyInfoIntro,
  CompanyInfoList,
  CompanyInfoWrapper,
  CompanyName,
} from './styles';

interface Props {
  data: CompanyDetailResponse;
}

const CompanyInfo = ({ data }: Props) => {
  return (
    <CompanyInfoWrapper>
      <CompanyInfoIntro>
        <CompanyName>{data.ResultData.name}</CompanyName>
        <CompanyDescription>{data.ResultData.description}</CompanyDescription>
      </CompanyInfoIntro>
      <CompanyInfoList>
        <li>
          <div>{data.ResultData.address}</div>
        </li>
        {data.ResultData.hasCampingProduct === 'N' && (
          <li>
            <div>
              참여일로부터 일주일 전까지 100% 환불 (그 외 환불 규정 확인)
            </div>
          </li>
        )}
        {data.ResultData.hasCampingProduct === 'N' && (
          <li>
            <div>
              운영시간 {data.ResultData.operationStartTime} ~&nbsp;
              {data.ResultData.operationEndTime}
            </div>
          </li>
        )}
        <li>
          <div>
            {data.ResultData.customerCenterNo.replace(
              /(02|0502|0503|0505|0506|0507|0508|.{3})(.+)(.{4})/,
              '$1-$2-$3',
            )}
          </div>
        </li>
      </CompanyInfoList>
    </CompanyInfoWrapper>
  );
};

export default CompanyInfo;
