import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import 'react-slideshow-image/dist/styles.css';
import 'swiper/css';
import { Swiper as SwiperClass } from 'swiper/types';
import {
  useCompanyDetailQuery,
  useCompanyReviewQuery,
} from '../hooks/reservation/useReservation';
import Toast from '../components/common/Toast';
import Skeleton from 'react-loading-skeleton';
import {
  CompanyDescription,
  CompanyInfoIntro,
  CompanyInfoList,
  CompanyInfoWrapper,
  CompanyName,
} from '../components/reservation/styles';
import SkeletonMenuItem from '../components/reservation/SkeletonMenuItem';
import CampingDetail from '../components/reservation/CampingDetail';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Slide } from 'react-slideshow-image';
import CompanyInfo from '../components/reservation/CompanyInfo';
import DetailHome from '../components/reservation/DetailHome';
import DetailMenu from '../components/reservation/DetailMenu';
import DetailReview from '../components/reservation/DetailReview';
import backButton from '../assets/reservation/ico_back_button.png';
import backButtonBlack from '../assets/reservation/ico_arrow_back.png';
import { useRecoilValue } from 'recoil';
import { imageWidthSize } from '../components/common/atom';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  background: #f1f3f5;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const SliderWrapper = styled.div`
  height: 100vw;
  max-height: 360px;
  position: relative;
`;

const SlidePagination = styled.div`
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  left: auto;
  right: 20px;
  bottom: 70px;
  width: auto;
  height: 22px;
  padding: 0 10px 1px;
  border-radius: 11px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1px;

  & > span {
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
  }
`;

const SlideEffect = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vw;
    max-height: 360px;
  }
`;

const ContentWrapper = styled.div`
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  position: sticky;
  top: 60px;
  z-index: 100;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 33.33%;
    height: 46px;
    background: none;
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    outline: none;
    opacity: 1;
    cursor: pointer;

    &.active {
      font-weight: 700;
    }

    &.active:after {
      content: '';
      display: block;
      clear: both;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #007eca;
      margin: 0 auto;
    }
  }
`;

const BackButtonWrapper = styled.div<{ scrollY: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: ${({ scrollY }) => (scrollY > 400 ? '#fff' : '')};

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const BackButton = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const CompanyNotice = styled.div`
  padding: 20px;
  background: #f1f3f5;
  font-size: 11px;
  line-height: 16px;
  color: #343a40;
`;

const Top = styled.div`
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  background: #fff;

  & > div:nth-of-type(1) {
    font-weight: 700;
    font-size: 16px;
    color: #212529;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    color: #076dae;
    cursor: pointer;
  }
`;

const Menu = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 5px solid #f1f3f5;
  background: #fff;
`;

const CompanyDetail = () => {
  const params = useParams();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const { isLoading, isError, data, remove } = useCompanyDetailQuery(
    params.companyId,
  );
  const { data: reviewData } = useCompanyReviewQuery(params.companyId);
  const navigate = useNavigate();
  const [toastShow, setToastShow] = useState(false);

  const [nowIndex, setNowIndex] = useState(1);

  const [scrollY, setScrollY] = useState(0); // 스크롤값을 저장하기 위한 상태
  const imageWidth = useRecoilValue(imageWidthSize);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  };

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    };
  });

  const changeScroll = useCallback(() => {
    if (scrollY > 475) {
      window.scrollTo({
        top: 475,
        behavior: 'smooth',
      });
    }
  }, [scrollY]);

  const onChange = useCallback((from: number, to: number) => {
    setNowIndex(to + 1);
  }, []);

  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const slideTo = useCallback(
    (index: number) => {
      swiperRef?.slideTo(index - 1, 300);
    },
    [swiperRef],
  );

  const onClickTabTitle = useCallback(
    (index: number) => {
      slideTo(index);
      setActiveTabIndex(index);
      changeScroll();
    },
    [changeScroll, slideTo],
  );

  const onClickBackButton = useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  const onClickAllMenuButton = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      onClickTabTitle(2);
    },
    [onClickTabTitle],
  );

  if (isLoading || isError) {
    return (
      <Base>
        <SliderWrapper>
          <Skeleton height={'100%'} />
        </SliderWrapper>
        <CompanyInfoWrapper>
          <CompanyInfoIntro>
            <CompanyName>
              <Skeleton />
            </CompanyName>
            <CompanyDescription>
              <Skeleton />
            </CompanyDescription>
          </CompanyInfoIntro>
          <CompanyInfoList>
            <li>
              <div>
                <Skeleton />
              </div>
            </li>
            <li>
              <div>
                <Skeleton />
              </div>
            </li>
            <li>
              <div>
                <Skeleton />
              </div>
            </li>
            <li>
              <div>
                <Skeleton />
              </div>
            </li>
          </CompanyInfoList>
        </CompanyInfoWrapper>
        <MenuWrapper>
          <span className={'active'}>홈</span>
          <span>메뉴</span>
          <span>후기</span>
        </MenuWrapper>
        <ContentWrapper>
          <Top>
            <div>대표상품</div>
            <div onClick={onClickAllMenuButton}>모두보기</div>
          </Top>
          <Menu>
            <SkeletonMenuItem />
            <SkeletonMenuItem />
            <SkeletonMenuItem />
            <SkeletonMenuItem />
          </Menu>
        </ContentWrapper>
      </Base>
    );
  }
  const isAutoPlay = data && data.data.ResultData.detailFiles.length > 1;
  return (
    <Base>
      <BackButtonWrapper scrollY={scrollY}>
        <BackButton
          onClick={onClickBackButton}
          src={scrollY > 400 ? backButtonBlack : backButton}
          alt="뒤로가기"
        />
      </BackButtonWrapper>
      <SliderWrapper>
        <Slide
          autoplay={isAutoPlay}
          arrows={false}
          transitionDuration={300}
          onChange={onChange}
        >
          {data?.data.ResultData.detailFiles.map((v) => (
            <SlideEffect key={v.filePath}>
              <div
                style={{
                  backgroundImage: `url('${v.filePath}?w=${imageWidth.top}')`,
                  backgroundPosition: 'center',
                }}
              ></div>
            </SlideEffect>
          ))}
        </Slide>
        <SlidePagination>
          <span>{nowIndex}</span>/
          <span>{data?.data.ResultData.detailFiles.length}</span>
        </SlidePagination>
      </SliderWrapper>

      {data && <CompanyInfo data={data.data} />}
      {data?.data.ResultData.hasCampingProduct === 'N' && (
        <MenuWrapper>
          <span
            className={activeTabIndex === 1 ? 'active' : ''}
            onClick={() => onClickTabTitle(1)}
          >
            홈
          </span>
          <span
            className={activeTabIndex === 2 ? 'active' : ''}
            onClick={() => onClickTabTitle(2)}
          >
            메뉴
          </span>
          <span
            className={activeTabIndex === 3 ? 'active' : ''}
            onClick={() => onClickTabTitle(3)}
          >
            후기
          </span>
        </MenuWrapper>
      )}
      {data?.data.ResultData.hasCampingProduct === 'N' && (
        <ContentWrapper>
          <Swiper
            onSwiper={(swiper) => setSwiperRef(swiper)}
            onActiveIndexChange={(swiper) => {
              setActiveTabIndex(swiper.activeIndex + 1);
              changeScroll();
            }}
          >
            <SwiperSlide>
              {data && reviewData && (
                <DetailHome
                  onClickAllMenuButton={onClickAllMenuButton}
                  review={reviewData.data.ResultData.content}
                  data={data.data.ResultData}
                  setToastShow={setToastShow}
                />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {data && <DetailMenu data={data.data.ResultData} />}
            </SwiperSlide>
            <SwiperSlide>
              {reviewData && (
                <DetailReview review={reviewData.data.ResultData.content} />
              )}
            </SwiperSlide>
          </Swiper>
          <CompanyNotice>
            (주)러브에그는 통신판매중개자로서 통신판매의 당사자가 아니며 상품의
            예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
          </CompanyNotice>
        </ContentWrapper>
      )}
      {data?.data.ResultData.hasCampingProduct === 'Y' && (
        <CampingDetail
          data={data?.data.ResultData}
          setToastShow={setToastShow}
        />
      )}
      {toastShow && (
        <Toast setToast={setToastShow} text={'주소가 복사되었습니다.'} />
      )}
    </Base>
  );
};

export default CompanyDetail;
