import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Product } from '../../types/market';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { imageWidthSize } from '../common/atom';

const ProductBox = styled.div`
  position: relative;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  & > img {
    width: 100%;
    height: 185px;
    border-bottom: 1px solid #e9ecef;
  }
`;
const Info = styled.div`
  padding: 16px 24px 24px;
  margin-top: -2px;
  background: #fff;

  & > h1 {
    font-size: 16px;
    font-weight: 500;
    color: #343a40;
    line-height: 23px;
    padding-bottom: 8px;
  }
`;
const SoldOut = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 0.5);

  & > span {
    position: absolute;
    left: 50%;
    top: 75px;
    transform: translate(-50%, 0%);
    font-size: 20px;
    font-weight: 700;
    color: #212529;
    line-height: 36px;
  }
`;
const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;

  & > h2 {
    font-size: 22px;
    font-weight: 800;
    color: #fc3e33;
    line-height: 33px;
  }
`;
const PriceLine = styled.li`
  font-size: 12px;
  color: #868e96;
  text-decoration-line: line-through;
  text-decoration-color: #868e96;
  line-height: 20px;
`;
const SalePrice = styled.li`
  font-size: 15px;
  font-weight: bold;
  color: #212529;
  line-height: 23px;
`;

const ProductItem = ({
  itemId,
  title,
  imgPath,
  price,
  discount,
  isSoldOut,
}: Product) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(`/market/${itemId}`);
  }, [itemId, navigate]);
  const imageWidth = useRecoilValue(imageWidthSize);

  return (
    <ProductBox onClick={onClick}>
      <img
        src={`${imgPath}?w=${imageWidth.reservationList}`}
        alt="제품이미지"
      />
      <Info>
        <h1>{title}</h1>
        <PriceBox>
          {discount !== 0 ? (
            <>
              <h2>{discount}%</h2>
              <ul>
                <PriceLine>{price.toLocaleString()}원</PriceLine>
                <SalePrice>
                  {Math.ceil(price - (price / 100) * discount).toLocaleString()}
                  원
                </SalePrice>
              </ul>
            </>
          ) : (
            <ul>
              <SalePrice>
                {price === 0 ? '' : `${price.toLocaleString()}원`}
              </SalePrice>
            </ul>
          )}
        </PriceBox>
      </Info>
      {isSoldOut === 'Y' ? (
        <SoldOut>
          <span>sold out</span>
        </SoldOut>
      ) : (
        ''
      )}
    </ProductBox>
  );
};

export default ProductItem;
