import styled from '@emotion/styled';
import locationIcon from '../../assets/reservation/ic_place.png';
import creditIcon from '../../assets/reservation/ic_credit.png';
import clockIcon from '../../assets/reservation/ic_time.png';
import callIcon from '../../assets/reservation/ic_call.png';

export const NoItem = styled.div<{ isCenter?: boolean }>`
  padding: 8px 16px 24px;
  font-size: 14px;
  line-height: 21px;
  color: #343a40;
  text-align: ${({ isCenter = true }) => (isCenter ? 'center' : 'left')};
`;

export const CompanyInfoWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 70px 20px 24px;
  box-sizing: border-box;
  background: #f1f3f5;
`;

export const CompanyInfoIntro = styled.div`
  position: absolute;
  top: calc(0% - 40px);
  width: calc(100% - 80px);
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
`;

export const CompanyName = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #212529;
`;

export const CompanyDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #495057;
`;

export const CompanyInfoList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;

  & > li:not(:last-of-type) {
    margin-bottom: 10px;
  }

  & > li {
    & > div {
      font-size: 12px;
      line-height: 15px;
      color: #343a40;
      display: flex;

      &::before {
        content: '';
        display: inline-block;
        width: 15px;
        margin-right: 5px;
        background-size: 15px;
        background-repeat: no-repeat;
      }
      & > span {
        width: 100%;
      }
    }

    &:nth-of-type(1) {
      & > div {
        &::before {
          background-image: url(${locationIcon});
        }
      }
    }

    &:nth-of-type(2) {
      & > div {
        &::before {
          background-image: url(${creditIcon});
        }
      }
    }

    &:nth-of-type(3) {
      & > div {
        &::before {
          background-image: url(${clockIcon});
        }
      }
    }

    &:nth-last-of-type(1) {
      & > div {
        &::before {
          background-image: url(${callIcon});
        }
      }
    }
  }
`;
