import axios, { AxiosResponse } from 'axios';
import { API_DOMAIN } from '../../utils/util';
import { useQuery, UseQueryResult } from 'react-query';
import {
  CompanyDetailResponse,
  CompanyListResponse,
  CompanyReviewResponse,
} from '../../types/reservation';

const getCompanyListApi = (search: string) => {
  return axios.get(
    API_DOMAIN + `/v1/reservation-company/list?search=${search}`,
  );
};

export const useCompanyListQuery = (
  search: string = '',
): UseQueryResult<AxiosResponse<CompanyListResponse>, Error> =>
  useQuery(['useCompanyListQuery'], () => getCompanyListApi(search), {
    retry: 3,
    keepPreviousData: true,
  });

const getCompanyDetailApi = (id: string) => {
  return axios.get(API_DOMAIN + `/v1/reservation-company/${id}`);
};

export const useCompanyDetailQuery = (
  id?: string,
): UseQueryResult<AxiosResponse<CompanyDetailResponse>, Error> =>
  useQuery(
    ['useCompanyDetailQuery'],
    () => {
      if (id) {
        return getCompanyDetailApi(id);
      }
    },
    {
      retry: 3,
      keepPreviousData: true,
    },
  );

const getCompanyReviewApi = (id: string) => {
  return axios.get(
    API_DOMAIN + `/v1/reservation-company/review?companyId=${id}`,
  );
};

export const useCompanyReviewQuery = (
  id?: string,
): UseQueryResult<AxiosResponse<CompanyReviewResponse>, Error> =>
  useQuery(
    ['useCompanyReviewQuery'],
    () => {
      if (id) {
        return getCompanyReviewApi(id);
      }
    },
    {
      retry: 3,
      keepPreviousData: true,
    },
  );
