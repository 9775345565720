import React from 'react';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

const ProductBox = styled.div`
  position: relative;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
`;
const Info = styled.div`
  padding: 16px 24px 24px;
  margin-top: -2px;
  background: #fff;

  & > h1 {
    font-size: 16px;
    font-weight: 500;
    color: #343a40;
    line-height: 23px;
    padding-bottom: 8px;
  }
`;
const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;

  & > h2 {
    font-size: 44px;
    font-weight: 800;
    color: #fc3e33;
    line-height: 33px;
    width: 20%;
    height: 100%;
  }
  & > ul {
    width: 80%;
  }
`;
const PriceLine = styled.li`
  font-size: 15px;
  color: #868e96;
  text-decoration-line: line-through;
  text-decoration-color: #868e96;
  line-height: 23px;
`;
const SalePrice = styled.li`
  font-size: 15px;
  font-weight: bold;
  color: #212529;
  line-height: 23px;
`;

const SkeletonMarketItem = () => {
  return (
    <ProductBox>
      <Skeleton
        style={{
          width: '100%',
          height: '185px',
        }}
      />
      <Info>
        <h1>
          <Skeleton />
        </h1>
        <PriceBox>
          <h2>
            <Skeleton />
          </h2>
          <ul>
            <PriceLine>
              <Skeleton />
            </PriceLine>
            <SalePrice>
              <Skeleton />
            </SalePrice>
          </ul>
        </PriceBox>
      </Info>
    </ProductBox>
  );
};

export default SkeletonMarketItem;
