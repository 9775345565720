import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SearchItem from '../components/reservation/SearchItem';
import { useNavigate } from 'react-router';
import ReservationTopSearchArea from '../components/reservation/ReservationTopSearchArea';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const SearchContent = styled.div`
  padding-top: 233px;
  box-sizing: border-box;
  min-height: 100vh;
  background: #f1f3f5;
  margin: 0 auto;
`;

const TopFixWrapper = styled.div`
  position: fixed;
  max-width: 360px;
  width: 100%;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 190px 20px 20px 20px;
  border-bottom: 1px solid #eee;
  background: #f1f3f5;

  & > div {
    font-size: 14px;
    font-weight: 700;
    color: #404040;
  }

  & > button {
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 600;
    color: #adb5bd;
    cursor: pointer;
  }
`;

const KeywordList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;

    & > div {
      font-weight: 400;
      font-size: 16px;
      color: #adb5bd;
      cursor: pointer;
    }

    & > img {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
`;

const Search = () => {
  const [keywordList, setKeywordList] = useState<Array<string>>([]);
  const navigate = useNavigate();

  const companySearch = useCallback(
    (text: string) => {
      const trimText = text.trim();
      const searchArr = [
        trimText,
        ...keywordList.filter((v) => v !== trimText),
      ];
      setKeywordList(searchArr);
      localStorage.setItem('search', JSON.stringify(searchArr));
      navigate('/reservation/search-result/' + encodeURIComponent(trimText));
    },
    [keywordList, navigate],
  );

  useEffect(() => {
    const saveSearch = localStorage.getItem('search');
    if (saveSearch !== null) {
      setKeywordList(JSON.parse(saveSearch));
    }
  }, []);

  const onDeleteClick = useCallback(
    (text: string) => {
      const searchArr = [...keywordList.filter((v) => v !== text)];
      setKeywordList(searchArr);
      localStorage.setItem('search', JSON.stringify(searchArr));
    },
    [keywordList],
  );

  const onAllDeleteClick = useCallback(() => {
    setKeywordList([]);
    localStorage.removeItem('search');
  }, []);

  return (
    <Base>
      <ReservationTopSearchArea
        setKeywordList={setKeywordList}
        keywordList={keywordList}
        backPath={'/reservation'}
      />
      <TopFixWrapper>
        <ContentTop>
          <div>최근 검색어</div>
          <button onClick={onAllDeleteClick}>전체삭제</button>
        </ContentTop>
      </TopFixWrapper>
      <SearchContent>
        <KeywordList>
          {keywordList.map((v: string) => (
            <SearchItem
              key={v}
              text={v}
              onDeleteClick={onDeleteClick}
              onSearch={companySearch}
            />
          ))}
        </KeywordList>
      </SearchContent>
    </Base>
  );
};
export default Search;
