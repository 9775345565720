import React, { useCallback } from 'react';
import AppLogo from '../../assets/common/img_app1.svg';
import { useSetRecoilState } from 'recoil';
import { downloadModalOpen } from './atom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const Base = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #000;
  opacity: 0.4;
`;

const motion1 = keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
`;

const ModalLayer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  max-width: 360px;
  height: 205px;
  margin: 0 auto;
  border-radius: 16px 16px 0 0;
  box-shadow: 3px 3px 6px 0 rgb(0 0 0 / 16%);
  box-sizing: border-box;
  background: #fff;
  animation: ${motion1} 0.5s ease-out;
`;

const ModalApp = styled.div`
  padding: 30px 30px 0;
  box-sizing: border-box;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 46px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background: #076dae;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 0.02em;
  }
`;

const AppInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & > img {
    margin-right: 15px;
    width: 60px;
  }

  & > div {
    & > div:not(:last-of-type) {
      margin-bottom: 8px;
    }

    & > div:nth-last-of-type(1) {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #404040;
      & > span {
        color: #076dae;
        font-weight: 700;
      }
      & > span:nth-last-of-type(1) {
        font-weight: 700;
        color: #495057;
      }
    }
  }
`;

const Close = styled.button`
  display: block;
  margin: 10px auto 0;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.02em;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #495057;
`;

const Content = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #404040;
`;
const AppDownloadModal = () => {
  const setDownloadModalOpen = useSetRecoilState(downloadModalOpen);

  const onClose = useCallback(() => {
    setDownloadModalOpen(false);
  }, [setDownloadModalOpen]);

  return (
    <Base>
      <Background></Background>
      <ModalLayer>
        <ModalApp>
          <AppInfo>
            <img src={AppLogo} alt="레저에 빠지다" />

            <div>
              <Title>레저에 대한 모든 것을 한번에!</Title>
              <Content>
                <span>앱 전용 쿠폰팩</span>
                으로 다양한 레저를 <span>최저가</span>로
                <br /> 이용해보세요.
              </Content>
            </div>
          </AppInfo>

          <a
            href="https://leisure.page.link/jdF1"
            target="_blank"
            onClick={onClose}
            rel="noopener noreferrer"
          >
            앱 설치 후 쿠폰 받기
          </a>
          <Close onClick={onClose}>괜찮습니다. 모바일웹으로 볼게요.</Close>
        </ModalApp>
      </ModalLayer>
    </Base>
  );
};

export default React.memo(AppDownloadModal);
