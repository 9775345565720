import React from 'react';
import { Tab } from '../../types/market';

const TabList = ({ categoryId, categoryName, getData, tabNum }: Tab) => {
  const clickHandler = () => {
    getData(categoryId);
  };

  return (
    <li
      onClick={clickHandler}
      className={tabNum === categoryId ? 'active' : ''}
    >
      {categoryName}
    </li>
  );
};

export default TabList;
