import React from 'react';
import { MarketDetailResponse } from '../../types/market';
import styled from '@emotion/styled';
import emptyStar from '../../assets/market/Star0.png';
import halfStar from '../../assets/market/Star0_5.png';
import oneStar from '../../assets/market/Star1.png';
import onePointFive from '../../assets/market/Star1_5.png';
import twoStar from '../../assets/market/Star2.png';
import twoPointFive from '../../assets/market/Star2_5.png';
import threeStar from '../../assets/market/Star3.png';
import threePointFive from '../../assets/market/Star3_5.png';
import fourStar from '../../assets/market/Star4.png';
import fourPointFive from '../../assets/market/Star4_5.png';
import fiveStar from '../../assets/market/Star5.png';
import { OptionGroups } from '../../types/market';

const MarketInfoWapper = styled.div`
  padding: 16px;
  background: #fff;
  border-bottom: 4px solid #f1f3f5;
`;

const PbInfo = styled.div`
  padding-bottom: 8px;

  & > h1 {
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
  }
  & > h1.active {
    color: #868e96;
  }
  & > p {
    font-size: 12px;
    line-height: 18px;
    color: #343a40;
    padding-top: 8px;
  }
`;

const Grade = styled.div`
  font-size: 12px;
  color: #495057;
  padding-top: 4px;

  & > img {
    height: 16px;
  }

  & > span {
    vertical-align: text-top;
  }
`;

const PbPrice = styled.ul`
  padding-top: 8px;
`;

const Line = styled.li`
  font-size: 12px;
  line-height: 15px;
  color: #adb5bd;
  text-decoration: line-through;
  padding-bottom: 2px;
`;

const Price = styled.li`
  font-size: 19px;
  font-weight: 800;
  color: #212529;
  line-height: 24px;
`;

const DeliveryInfo = styled.ul`
  border-top: 1px solid #e9ecef;
  padding-top: 8px;

  & > li {
    font-size: 12px;
    color: #495057;
    line-height: 15px;
  }
  & > li:not(:last-of-type) {
    padding-bottom: 4px;
  }
`;

interface Props {
  data: MarketDetailResponse;
  options: OptionGroups;
}

const MarketInfo = ({ data, options }: Props) => {
  const gradeString = data.ResultData.content.reviewRating
    .toFixed(1)
    .toString();

  const stock = options.options.map((o) => o.stock);

  return (
    <MarketInfoWapper>
      <PbInfo>
        {stock.every(function (x) {
          return x === 0;
        }) ? (
          <h1 className="active">{data.ResultData.content.title}[품절]</h1>
        ) : (
          <h1>{data.ResultData.content.title}</h1>
        )}
        <Grade>
          {data.ResultData.content.reviewRating === 5 ? (
            <img src={fiveStar} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 4.5 ? (
            <img src={fourPointFive} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 4 ? (
            <img src={fourStar} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 3.5 ? (
            <img src={threePointFive} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 3 ? (
            <img src={threeStar} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 2.5 ? (
            <img src={twoPointFive} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 2 ? (
            <img src={twoStar} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 1.5 ? (
            <img src={onePointFive} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 1 ? (
            <img src={oneStar} alt={gradeString} />
          ) : data.ResultData.content.reviewRating >= 1 ? (
            <img src={halfStar} alt={gradeString} />
          ) : (
            <img src={emptyStar} alt={gradeString} />
          )}{' '}
          <span>({data.ResultData.content.reviewRating.toFixed(1)})</span>
        </Grade>
        <p>{data.ResultData.content.description}</p>
        <PbPrice>
          {data.ResultData.content.discount !== 0 ? (
            <Line>{data.ResultData.content.price.toLocaleString()}원</Line>
          ) : (
            ''
          )}
          <Price>
            {Math.ceil(
              data.ResultData.content.price -
                (data.ResultData.content.price / 100) *
                  data.ResultData.content.discount,
            ).toLocaleString()}
            원
          </Price>
        </PbPrice>
      </PbInfo>
      <DeliveryInfo>
        <li>국내배송 (3~7일 소요)</li>
        <li>택배 ({data.ResultData.content.courier})</li>
        <li>
          배송비 {data.ResultData.content.deliveryCharge.toLocaleString()}원 (
          {data.ResultData.content.freeDeliveryPrice.toLocaleString()}원 이상
          구매 시 무료)
        </li>
      </DeliveryInfo>
    </MarketInfoWapper>
  );
};

export default MarketInfo;
