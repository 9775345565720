import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Company } from '../../types/reservation';
import { useNavigate } from 'react-router';
import 'react-loading-skeleton/dist/skeleton.css';
import SkeletonImage from '../common/SkeletonImage';
import { useRecoilValue } from 'recoil';
import { imageWidthSize } from '../common/atom';

const Base = styled.div`
  & + & {
    padding-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  background: #fff;
  border-radius: 10px 10px 0;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-top: 1px solid #e9ecef;
  cursor: pointer;

  & > div > img {
    width: 100%;
    max-height: 203px;
    border-radius: 10px 10px 0 0;
  }
`;

const TextWrapper = styled.div`
  padding: 8px 12px 12px;
  background: #fff;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
`;

const Sgg = styled.div`
  color: #666;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
`;

const Name = styled.div`
  color: #000;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.5px;
`;

const Tag = styled.div`
  color: #6db1f7;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 0;
  letter-spacing: -0.2px;
`;

const ViewCount = styled.div`
  color: #666;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;

  & > span {
    color: #1631b8;
  }
`;

const CompanyItem = ({ id, name, sgg, tag, mainFiles, viewCount }: Company) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(`/reservation/${id}`);
  }, [id, navigate]);
  const imageWidth = useRecoilValue(imageWidthSize);

  return (
    <Base>
      <ImageWrapper onClick={onClick}>
        <SkeletonImage
          image={`${mainFiles[0].filePath}?w=${imageWidth.reservationList}`}
          style={{
            borderRadius: '10px 10px 0 0',
            height: '200px',
            paddingTop: '5px',
          }}
          alt={'사업장'}
        />
      </ImageWrapper>
      <TextWrapper onClick={onClick}>
        <Sgg>{sgg}</Sgg>
        <Name>{name}</Name>
        {tag !== '' && <Tag>{'#' + tag.split(',').join(' #')}</Tag>}
        <ViewCount>
          <span>{viewCount}</span>명이 봤어요
        </ViewCount>
      </TextWrapper>
    </Base>
  );
};

export default CompanyItem;
