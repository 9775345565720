import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import marker from '../../assets/reservation/ico_location.png';

const MapArea = styled.div`
  position: relative;
  height: 41.667vw;
  max-height: 150px;
`;

const MapWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
`;

const MapAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #404040;

  & > div {
    display: flex;
    align-items: center;
    width: 88%;
    text-align: left;
    & > img {
      width: 14px;
      height: 17px;
      margin-right: 5px;
      vertical-align: 0;
    }
  }
  & > button {
    border: none;
    background: none;
    font-size: 12px;
    font-weight: 400;
    color: #007eca;
    cursor: pointer;
    width: 50px;
  }
`;

interface Props {
  lon: string;
  lat: string;
  iconUrl?: string;
  address?: string;
  setToastShow?: (isShow: boolean) => void;
}

const Map = ({
  lon,
  lat,
  iconUrl = '../../ico_location1.png',
  address = '',
  setToastShow,
}: Props) => {
  const mapElement = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    const position = new naver.maps.LatLng(parseFloat(lat), parseFloat(lon));
    const mapOptions: naver.maps.MapOptions = {
      center: position,
      zoom: 17,
      draggable: false,
      pinchZoom: false,
      scrollWheel: false,
      keyboardShortcuts: false,
      disableDoubleTapZoom: true,
      disableDoubleClickZoom: true,
      disableTwoFingerTapZoom: true,
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: position.destinationPoint(0, 0),
      map: map,
      icon: {
        url: iconUrl,
        size: new naver.maps.Size(24, 24),
        origin: new naver.maps.Point(0, 0),
      },
    });
  }, [iconUrl, lat, lon]);

  const handleCopyClipBoard = async (text: string) => {
    try {
      if (setToastShow) {
        setToastShow(true);
        await navigator.clipboard.writeText(text);
      }
    } catch (error) {}
  };

  return (
    <MapWrapper>
      <MapArea>
        <div ref={mapElement} style={{ height: '100%' }} />
      </MapArea>
      {address !== '' && (
        <MapAddress>
          <div>
            <img src={marker} alt="마커" />
            <span>{address}</span>
          </div>
          <button onClick={() => handleCopyClipBoard(address)}>복사</button>
        </MapAddress>
      )}
    </MapWrapper>
  );
};

export default Map;
