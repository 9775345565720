import styled from '@emotion/styled';

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;
