import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useSetRecoilState } from 'recoil';
import { downloadModalOpen } from '../common/atom';

const Base = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  max-width: 360px;
  height: 76px;
  margin: 0 auto;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const Background = styled.div`
  height: calc(100% - 32px);
  padding: 16px;
  background: #fff;
  backdrop-filter: blur(4px);
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  gap: 8px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: calc(100% - 18px);
    padding: 8px 12px;
    border: 1px solid #076dae;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    cursor: pointer;
  }

  & > div:nth-of-type(1) {
    color: #076dae;
  }
  & > div:nth-of-type(2) {
    color: #fff;
    background: #076dae;
  }
`;

const MarketFixed = () => {
  const setOpen = useSetRecoilState(downloadModalOpen);
  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Base>
      <Background>
        <Menu>
          <div onClick={onClick}>장바구니 담기</div>
          <div onClick={onClick}>결제하기</div>
        </Menu>
      </Background>
    </Base>
  );
};

export default MarketFixed;
