import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ReservationTopSearchArea from '../components/reservation/ReservationTopSearchArea';
import { useCompanyListQuery } from '../hooks/reservation/useReservation';
import { useParams } from 'react-router';
import CompanyItem from '../components/reservation/CompanyItem';
import { useLocation } from 'react-router-dom';
import SkeletonCompanyItem from '../components/reservation/SkeletonCompanyItem';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const CompanyList = styled.div`
  padding: 195px 10px 30px 10px;
  box-sizing: border-box;
  min-height: 100vh;
  background: #f1f3f5;
  margin: 0 auto;
`;

const SearchResult = () => {
  const [keywordList, setKeywordList] = useState<Array<string>>([]);
  const params = useParams();
  const { isLoading, isError, data, refetch, remove } = useCompanyListQuery(
    params.text,
  );

  useEffect(() => {
    const saveSearch = localStorage.getItem('search');
    if (saveSearch !== null) {
      setKeywordList(JSON.parse(saveSearch));
    }
  }, []);

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const { pathname } = useLocation();
  useEffect(() => {
    refetch();
  }, [pathname, refetch]);

  if (isLoading || isError) {
    return (
      <Base>
        <ReservationTopSearchArea
          setKeywordList={setKeywordList}
          keywordList={keywordList}
          backPath={'/reservation/search'}
          text={params.text}
        />
        <CompanyList>
          {Array.from({ length: Math.ceil(window.innerHeight / 250) }).map(
            (v, i) => (
              <SkeletonCompanyItem key={i} />
            ),
          )}
        </CompanyList>
      </Base>
    );
  }

  return (
    <Base>
      <ReservationTopSearchArea
        setKeywordList={setKeywordList}
        keywordList={keywordList}
        backPath={'/reservation/search'}
        text={params.text}
      />
      <CompanyList>
        {data?.data.ResultData.content
          .filter((v) => v.mainFiles.length > 0)
          .map((v) => (
            <CompanyItem
              key={v.id}
              id={v.id}
              name={v.name}
              sgg={v.sgg}
              tag={v.tag}
              mainFiles={v.mainFiles}
              viewCount={v.viewCount}
            />
          ))}
      </CompanyList>
    </Base>
  );
};

export default SearchResult;
