import React from 'react';
import styled from '@emotion/styled';
import Skeleton from 'react-loading-skeleton';

const Base = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 20px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  cursor: pointer;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  width: 78%;
  text-align: left;

  & > div {
    width: 100%;
  }
`;

const ItemPrice = styled.div`
  text-align: right;
  width: 20%;

  & > div {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #404040;

    &.price-before {
      margin-bottom: 1px;
      font-size: 11px;
      font-weight: 400;
      color: #bbb;
      text-decoration: line-through;
    }
  }
`;

const SkeletonMenuItem = () => {
  return (
    <Base>
      <ItemInfo>
        <Skeleton
          style={{
            marginRight: '15px',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
          }}
        />
        <div>
          <div>
            <Skeleton />
          </div>
          <div>
            <Skeleton />
          </div>
        </div>
      </ItemInfo>
      <ItemPrice>
        <div className="price-before">
          <Skeleton />
        </div>
        <div>
          <Skeleton />
        </div>
      </ItemPrice>
    </Base>
  );
};

export default SkeletonMenuItem;
