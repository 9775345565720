import React from 'react';
import styled from '@emotion/styled';
import { CompanyDetail, CompanyReview } from '../../types/reservation';
import MenuItem from './MenuItem';
import ReviewItem from './ReviewItem';
import option1 from '../../assets/reservation/ico_option1.png';
import option1_gray from '../../assets/reservation/ico_option1_gray.png';
import option2 from '../../assets/reservation/ico_option2.png';
import option2_gray from '../../assets/reservation/ico_option2_gray.png';
import option3 from '../../assets/reservation/ico_option3.png';
import option3_gray from '../../assets/reservation/ico_option3_gray.png';
import option4 from '../../assets/reservation/ico_option4.png';
import option4_gray from '../../assets/reservation/ico_option4_gray.png';
import option5 from '../../assets/reservation/ico_option5.png';
import option5_gray from '../../assets/reservation/ico_option5_gray.png';
import option6 from '../../assets/reservation/ico_option6.png';
import option6_gray from '../../assets/reservation/ico_option6_gray.png';
import option7 from '../../assets/reservation/ico_option7.png';
import option7_gray from '../../assets/reservation/ico_option7_gray.png';
import option8 from '../../assets/reservation/ico_option8.png';
import option8_gray from '../../assets/reservation/ico_option8_gray.png';
import option9 from '../../assets/reservation/ico_option9.png';
import option9_gray from '../../assets/reservation/ico_option9_gray.png';
import option10 from '../../assets/reservation/ico_option10.png';
import option10_gray from '../../assets/reservation/ico_option10_gray.png';
import { NoItem } from './styles';
import SkeletonImage from '../common/SkeletonImage';
import Map from './Map';
import { useRecoilValue } from 'recoil';
import { imageWidthSize } from '../common/atom';

const Base = styled.div`
  width: 100%;
`;

const Top = styled.div`
  width: calc(100% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;

  & > div:nth-of-type(1) {
    font-weight: 700;
    font-size: 16px;
    color: #212529;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    color: #076dae;
    cursor: pointer;
  }
`;

const Menu = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 5px solid #f1f3f5;
`;

const Review = styled.ol`
  padding: 0 20px;
  list-style: none;
  margin: 0;
`;

const ReviewStat = styled.ol<{ width: number }>`
  padding: 15px 20px;
  list-style: none;
  margin: 0;

  & > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  & > li:nth-of-type(1) {
    & > div:nth-of-type(1) {
      width: 75px;
      font-size: 12px;
      font-weight: 700;
      color: #404040;
    }

    & > div:nth-of-type(2) {
      width: calc(100% - 80px);
      height: 20px;
      border-radius: 10px;
      box-shadow: 0 0 2px 2px rgb(0 0 0 / 5%);

      & > span {
        background: #007eca;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        width: ${({ width }) => `${width}%`};
      }
    }
  }

  & > li:nth-of-type(2) {
    & > div:nth-of-type(1) {
      width: 75px;
      font-size: 12px;
      font-weight: 700;
      color: #404040;
    }

    & > div:nth-of-type(2) {
      width: calc(100% - 80px);
      height: 20px;
      border-radius: 10px;
      box-shadow: 0 0 2px 2px rgb(0 0 0 / 5%);

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: ${({ width }) => `${100 - width}%`};
        border-radius: 10px;
        box-sizing: border-box;
        background: #404040;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
    }
  }
`;

const DetailInfo = styled.div`
  border-top: 5px solid #f1f3f5;
`;

const DetailDescriptionImages = styled.div`
  display: flex;
  flex-direction: column;
  & > div > img {
    width: 100%;
  }
`;

const FacilityWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  & > ol {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const FacilityItem = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: calc(20% - 8px);
  height: 15.556vw;
  max-height: 56px;
  margin: 4px 10px 4px 0;
  padding-bottom: 4px;
  border: 1px solid ${({ isActive }) => (isActive ? '#007eca' : '#EEEEEE')};
  border-radius: 5px;
  box-sizing: border-box;

  &:nth-of-type(5n) {
    margin-right: 0;
  }
  & > img {
    zoom: 0.5;
  }

  & > div {
    font-size: 11px;
    font-weight: 700;
    color: ${({ isActive }) => (isActive ? '#007eca' : '#EEEEEE')};
  }
`;

const RefundWrapper = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

const RefundInfo = styled.div`
  margin-bottom: 25px;
  text-align: left;
  & > p {
    text-indent: -10px;
    font-size: 12px;
    font-weight: 400;
    color: #404040;
    line-height: 20px;
    margin: 0;
    padding: 0 0 0 10px;
    word-break: break-all;
  }
`;

const RefundTableWrapper = styled.div`
  & > div:nth-of-type(1) {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #404040;
    text-align: center;
  }
  & > div:nth-of-type(2) {
    font-size: 11px;
    line-height: 15px;
    color: #404040;
    text-align: left;
  }

  & > table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
    border: 1px solid #ebebeb;

    th {
      color: #404040;
      background: #f8f8f8;
      padding: 5px 10px;
      border-bottom: 1px solid #ebebeb;
    }

    & > thead > tr > th {
      font-size: 11px;
      font-weight: 700;
    }

    & > tbody > tr > th {
      font-size: 10px;
      box-sizing: border-box;
    }
    & > tbody > tr > th:not(:last-of-type),
    & > tbody > tr > td:not(:last-of-type) {
      border-right: 1px solid #ebebeb;
    }

    & > tbody > tr > td {
      font-size: 10px;
      color: #404040;
      text-align: center;
      padding: 10px;
      box-sizing: border-box;
    }

    & > tbody > tr:nth-of-type(2) > td:nth-of-type(1) > span {
      color: #007eca;
    }

    & > tbody > tr:nth-of-type(2) > td:nth-of-type(4) > span {
      color: #ff0000;
    }
  }
`;

interface Props {
  onClickAllMenuButton: (e: React.MouseEvent<HTMLDivElement>) => void;
  review: Array<CompanyReview>;
  data: CompanyDetail;
  setToastShow: (isShow: boolean) => void;
}

const DetailHome = ({
  onClickAllMenuButton,
  review,
  data,
  setToastShow,
}: Props) => {
  const imageWidth = useRecoilValue(imageWidthSize);

  return (
    <Base>
      <Top>
        <div>대표상품</div>
        {data.normalProducts.length > 0 && (
          <div onClick={onClickAllMenuButton}>모두보기</div>
        )}
      </Top>
      {data.normalProducts.length < 1 && (
        <NoItem>아직 등록된 상품이 없습니다.</NoItem>
      )}
      <Menu>
        {data.normalProducts.map((v) => (
          <MenuItem
            key={v.id}
            image={v.imgPath}
            title={v.title}
            description={v.description}
            discount={v.discount}
            price={v.price}
          />
        ))}
      </Menu>
      <div>
        <Top>
          <div>후기({review.length})</div>
        </Top>
        {review.length < 1 && (
          <NoItem isCenter={false}>
            아직 후기가 없어요. <br />첫 번째 후기 작성자가 되어주세요!
          </NoItem>
        )}
        {review.length > 0 && (
          <ReviewStat width={data.ratings}>
            <li>
              <div>또 가고싶어요</div>
              <div>
                <span>{data.ratings}%</span>
              </div>
            </li>
            <li>
              <div className="tit">안가고싶어요</div>
              <div className="percent">
                <span>{100 - data.ratings}%</span>
              </div>
            </li>
          </ReviewStat>
        )}
        {review.length > 0 && (
          <Review>
            <ReviewItem
              profileImagePath={review[0].profileImagePath}
              name={review[0].name}
              regDt={review[0].regDate}
              reviewTxt={review[0].reviewTxt}
              message={review[0].message}
              boatName={review[0].boatName}
              leisureName={review[0].leisureName}
              title={review[0].title}
              images={review[0].images}
            />
          </Review>
        )}
      </div>
      <DetailInfo>
        <Top>
          <div>사업장 상세정보</div>
        </Top>
        <DetailDescriptionImages>
          {data.descriptionFiles.map((v) => (
            <SkeletonImage
              key={v.filePath}
              image={`${v.filePath}?w=${imageWidth.detail}`}
              alt={'상세'}
              style={{
                height: '300px',
              }}
            />
          ))}
        </DetailDescriptionImages>
      </DetailInfo>
      <DetailInfo>
        <Top>
          <div>편의시설</div>
        </Top>
        <FacilityWrapper>
          <ol>
            <FacilityItem isActive={data.parkingLot === 'Y'}>
              {data.parkingLot === 'Y' && <img src={option1} alt="주차장" />}
              {data.parkingLot === 'N' && (
                <img src={option1_gray} alt="주차장" />
              )}
              <div>주차장</div>
            </FacilityItem>
            <FacilityItem isActive={data.showerFacility === 'Y'}>
              {data.showerFacility === 'Y' && (
                <img src={option2} alt="샤워실" />
              )}
              {data.showerFacility === 'N' && (
                <img src={option2_gray} alt="샤워실" />
              )}
              <div>샤워실</div>
            </FacilityItem>
            <FacilityItem isActive={data.dressingRoom === 'Y'}>
              {data.dressingRoom === 'Y' && <img src={option3} alt="탈의실" />}
              {data.dressingRoom === 'N' && (
                <img src={option3_gray} alt="탈의실" />
              )}
              <div>탈의실</div>
            </FacilityItem>
            <FacilityItem isActive={data.wifi === 'Y'}>
              {data.wifi === 'Y' && <img src={option4} alt="와이파이" />}
              {data.wifi === 'N' && <img src={option4_gray} alt="와이파이" />}
              <div>와이파이</div>
            </FacilityItem>
            <FacilityItem isActive={data.canteen === 'Y'}>
              {data.canteen === 'Y' && <img src={option5} alt="매점" />}
              {data.canteen === 'N' && <img src={option5_gray} alt="매점" />}
              <div>매점</div>
            </FacilityItem>
            <FacilityItem isActive={data.toilet === 'Y'}>
              {data.toilet === 'Y' && <img src={option6} alt="화장실" />}
              {data.toilet === 'N' && <img src={option6_gray} alt="화장실" />}
              <div>화장실</div>
            </FacilityItem>
            <FacilityItem isActive={data.locker === 'Y'}>
              {data.locker === 'Y' && <img src={option7} alt="개인락카" />}
              {data.locker === 'N' && <img src={option7_gray} alt="개인락카" />}
              <div>개인락카</div>
            </FacilityItem>
            <FacilityItem isActive={data.restaurant === 'Y'}>
              {data.restaurant === 'Y' && <img src={option8} alt="음식점" />}
              {data.restaurant === 'N' && (
                <img src={option8_gray} alt="음식점" />
              )}
              <div>음식점</div>
            </FacilityItem>
            <FacilityItem isActive={data.lounge === 'Y'}>
              {data.lounge === 'Y' && <img src={option9} alt="휴게실" />}
              {data.lounge === 'N' && <img src={option9_gray} alt="휴게실" />}
              <div>휴게실</div>
            </FacilityItem>
            <FacilityItem isActive={data.withAnimal === 'Y'}>
              {data.withAnimal === 'Y' && <img src={option10} alt="동물동반" />}
              {data.withAnimal === 'N' && (
                <img src={option10_gray} alt="동물동반" />
              )}
              <div>동물동반</div>
            </FacilityItem>
          </ol>
        </FacilityWrapper>
      </DetailInfo>
      <div>
        <Top>
          <div>장소</div>
        </Top>
        <Map
          lon={data.lon}
          lat={data.lat}
          setToastShow={setToastShow}
          address={data.address}
        />
      </div>
      <div>
        <Top>
          <div>환불규정</div>
        </Top>
        <RefundWrapper>
          <RefundInfo>
            <p>
              - 당일 예약시 환불이 불가합니다. <br />
              단, 예약 결제 시점부터 10분 이내 전액 취소 가능합니다.{' '}
            </p>
            <p>
              - 예약 결제 후 10분 이내라도 예약시간이 경과된 경우는 취소 및
              환불이 불가합니다.
            </p>
            <p>- 예약 취소 시 취소 신청 시점에 따른 위약금이 발생합니다.</p>
            <p>
              - 업체 사정으로 인한 예약 취소 시 100% 환불을 원칙으로 합니다.
            </p>
          </RefundInfo>
          <RefundTableWrapper>
            <div>&lt;예약 취소 시 환불 위약금 기준표&gt;</div>
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>예약일기준</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>3일전</th>
                  <th>2일전</th>
                  <th>1일전</th>
                  <th>당일</th>
                </tr>
                <tr>
                  <td>
                    <span>전액환불</span>
                  </td>
                  <td>70% 환불</td>
                  <td>50% 환불</td>
                  <td>
                    <span>환불불가</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              *레저 현장상품 특성을 고려, 예약 취소시 위약금이 발생되오니 신중한
              결제 부탁드립니다.
            </div>
          </RefundTableWrapper>
        </RefundWrapper>
      </div>
    </Base>
  );
};

export default DetailHome;
