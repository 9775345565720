import React from 'react';

import logo from '../../assets/common/logo.png';
import styled from '@emotion/styled';

const Base = styled.div`
  height: 56px;
  border-bottom: 1px solid #179ac2;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background: #ffffff;
`;

const LogoImg = styled.img`
  height: 60%;
`;
const Logo = () => {
  return (
    <Base>
      <LogoImg src={logo} alt="레저에빠지다" />
    </Base>
  );
};

export default React.memo(Logo);
