import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import backButton from '../assets/reservation/ico_back_button.png';
import backButtonBlack from '../assets/reservation/ico_arrow_back.png';
import { useMarketDetailQuery } from '../hooks/market/useMarket';
import MarketInfo from '../components/market/MarketInfo';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { downloadModalOpen, imageWidthSize } from '../components/common/atom';
import MarketFixed from '../components/market/MarketFixed';
import Skeleton from 'react-loading-skeleton';
import SkeletonMarketInfo from '../components/market/SkeletonMarketInfo';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  background: #f1f3f5;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const SliderWrapper = styled.div`
  height: 100vw;
  max-height: 360px;
  position: relative;

  video {
    width: 360px;
    height: 360px;
    object-fit: cover;
  }
`;

const SlidePagination = styled.div`
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  left: auto;
  right: 20px;
  bottom: 20px;
  width: auto;
  height: 22px;
  padding: 0 10px 1px;
  border-radius: 11px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1px;

  & > span {
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
  }
`;

const SlideEffect = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vw;
    max-height: 360px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #E9ECEF;
  position: sticky;
  top: 59px;
  z-index: 100;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: 44px;
    background: none;
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    outline: none;
    opacity: 1;
    cursor: pointer;
`;

const ContentWrapper = styled.div`
  width: 100%;

  & > img {
    display: block;
    width: 100%;
  }
`;

const BackButtonWrapper = styled.div<{ scrollY: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 16px;
  background: ${({ scrollY }) => (scrollY > 400 ? '#fff' : '')};

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;
const BackButton = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const CompanyNotice = styled.div`
  padding: 16px 20px 96px;
  background: #f1f3f5;
  font-size: 11px;
  font-weight: 400;
  color: #343a40;
  line-height: 16px;
`;

const ProductDetail = () => {
  const params = useParams();
  const { isLoading, isError, data } = useMarketDetailQuery(params.itemId);
  const navigate = useNavigate();

  const [nowIndex, setNowIndex] = useState(1);

  const [scrollY, setScrollY] = useState(0); // 스크롤값을 저장하기 위한 상태
  const imageWidth = useRecoilValue(imageWidthSize);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
    };
  });

  const onChange = useCallback((from: number, to: number) => {
    setNowIndex(to + 1);
  }, []);

  const onClickBackButton = useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  const setOpen = useSetRecoilState(downloadModalOpen);
  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  if (isLoading || isError) {
    return (
      <Base>
        <SliderWrapper>
          <Skeleton height={'100%'} />
        </SliderWrapper>
        <SkeletonMarketInfo />
        <MenuWrapper>
          <span>상품후기{data?.data.ResultData.content.reviewCount}</span>
          <span>교환·반품</span>
        </MenuWrapper>
        <ContentWrapper>
          <Skeleton
            style={{
              height: '400px',
            }}
          />
        </ContentWrapper>
      </Base>
    );
  }

  return (
    <Base>
      <BackButtonWrapper scrollY={scrollY}>
        <BackButton
          onClick={onClickBackButton}
          src={scrollY > 400 ? backButtonBlack : backButton}
          alt="뒤로가기"
        />
      </BackButtonWrapper>
      <SliderWrapper>
        <Slide arrows={false} transitionDuration={300} onChange={onChange}>
          {data?.data.ResultData.content.topImages.map((v) => (
            <SlideEffect key={v.imgPath}>
              {v.fileFormat === 'IMAGE' ? (
                <div
                  style={{
                    backgroundImage: `url('${v.imgPath}?w=${imageWidth.top}')`,
                    backgroundPosition: 'center',
                  }}
                ></div>
              ) : (
                <video controls autoPlay loop muted>
                  <source src={v.imgPath} type="video/mp4" />
                </video>
              )}
            </SlideEffect>
          ))}
        </Slide>
        <SlidePagination>
          <span>{nowIndex}</span>/
          <span>{data?.data.ResultData.content.topImages.length}</span>
        </SlidePagination>
      </SliderWrapper>
      {data && (
        <MarketInfo
          data={data.data}
          options={data.data.ResultData.content.optionGroups[0]}
        />
      )}
      <MenuWrapper>
        <span onClick={onClick}>
          상품후기({data?.data.ResultData.content.reviewCount})
        </span>
        <span onClick={onClick}>교환·반품</span>
      </MenuWrapper>
      <ContentWrapper>
        {data?.data.ResultData.content.detailImages.map((v) => (
          <img key={v.imgPath} src={v.imgPath} alt="상세설명" />
        ))}
      </ContentWrapper>
      <CompanyNotice>
        (주)러브에그는 통신판매중개자로서 통신판매의 당사자가 아니며 상품의
        예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
      </CompanyNotice>
      <MarketFixed />
    </Base>
  );
};

export default ProductDetail;
