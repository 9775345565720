import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useSetRecoilState } from 'recoil';
import { downloadModalOpen } from '../common/atom';
import { css } from '@emotion/react';
import SkeletonImage from '../common/SkeletonImage';

const Base = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 20px 16px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  cursor: pointer;
`;

const ItemInfo = styled.div<{ isGeneral: boolean }>`
  display: flex;
  align-items: center;
  width: 78%;
  text-align: left;

  & > div:nth-of-type(1) > img {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & > div:nth-of-type(2) {
    ${({ isGeneral }) =>
      isGeneral &&
      css`
        & > div:nth-of-type(1) {
          display: inline-block;
          margin-bottom: 2px;
          padding: 1px 5px;
          box-sizing: border-box;
          font-size: 9px;
          font-weight: 700;
          background: #eef8ff;
          color: #007eca;
        }
      `};

    & > div:nth-of-type(2) {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #404040;
      text-align: left;
    }

    & > div:nth-of-type(3) {
      margin-top: 2px;
      font-size: 12px;
      line-height: 15px;
      color: #888;
      text-align: left;
    }
  }
`;

const ItemPrice = styled.div`
  text-align: right;

  & > div {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #404040;

    &.price-before {
      margin-bottom: 1px;
      font-size: 11px;
      font-weight: 400;
      color: #bbb;
      text-decoration: line-through;
    }
  }
`;

interface Props {
  image: string;
  title: string;
  description: string;
  price: number;
  discount: number;
  isGeneral?: boolean;
}

const MenuItem = ({
  image,
  price,
  title,
  description,
  discount,
  isGeneral = false,
}: Props) => {
  const setOpen = useSetRecoilState(downloadModalOpen);
  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <Base onClick={onClick}>
      <ItemInfo isGeneral={isGeneral}>
        <SkeletonImage
          image={image}
          style={{
            marginRight: '15px',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
          }}
          alt={'상품'}
        />
        <div>
          <div>{isGeneral ? '운행중' : ''}</div>
          <div>{title}</div>
          <div>{description}</div>
        </div>
      </ItemInfo>
      <ItemPrice>
        {discount > 0 && (
          <div className="price-before">{`${price.toLocaleString()}원`}</div>
        )}
        <div>{`${((price * (100 - discount)) / 100).toLocaleString()}원`}</div>
      </ItemPrice>
    </Base>
  );
};

export default MenuItem;
