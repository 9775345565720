import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import BottomMenu from '../components/common/BottomMenu';
import Logo from '../components/common/Logo';
import SearchIcon from '../assets/common/ic_search.png';
import Footer from '../components/common/Footer';
import { useCompanyListQuery } from '../hooks/reservation/useReservation';
import CompanyItem from '../components/reservation/CompanyItem';
import { useNavigate } from 'react-router';
import SkeletonCompanyItem from '../components/reservation/SkeletonCompanyItem';
import { Header } from '../components/common/styles';

const Base = styled.div`
  max-width: 360px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 4px 2px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  background: #f1f3f5;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  padding: 4px 16px;
  align-items: center;
  justify-content: space-between;
  background: #f1f3f5;

  & > div {
    font-size: 16px;
    color: #495057;
    padding: 10px 0;
    font-weight: 600;
  }

  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const CompanyList = styled.div`
  padding: 110px 16px 16px 16px;
`;

const Reservation = () => {
  const { isLoading, isError, data, remove } = useCompanyListQuery();

  const navigate = useNavigate();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLImageElement>) => {
      e.stopPropagation();
      navigate('/reservation/search');
    },
    [navigate],
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  if (isLoading || isError) {
    return (
      <Base>
        <Header>
          <Logo />
          <Title>
            <div>레저예약</div>
            <img src={SearchIcon} alt="검색" />
          </Title>
        </Header>
        <CompanyList>
          {Array.from({ length: Math.ceil(window.innerHeight / 250) }).map(
            (v, i) => (
              <SkeletonCompanyItem key={i} />
            ),
          )}
        </CompanyList>
      </Base>
    );
  }

  return (
    <Base>
      <Header>
        <Logo />
        <Title>
          <div>레저예약</div>
          <img src={SearchIcon} alt="검색" onClick={onClick} />
        </Title>
      </Header>
      <CompanyList>
        {data?.data.ResultData.content
          .filter((v) => v.mainFiles.length > 0)
          .map((v) => (
            <CompanyItem
              key={v.id}
              id={v.id}
              name={v.name}
              sgg={v.sgg}
              tag={v.tag}
              mainFiles={v.mainFiles}
              viewCount={v.viewCount}
            />
          ))}
      </CompanyList>
      <Footer />
      <BottomMenu />
    </Base>
  );
};

export default Reservation;
