import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import footer1 from '../../assets/common/market_off.png';
import footer1On from '../../assets/common/market_on.png';
import footer2 from '../../assets/common/chat_off.png';
import footer3 from '../../assets/common/reservation_off.png';
import footer3On from '../../assets/common/reservation_on.png';
import footer4 from '../../assets/common/myaccount_off.png';
import { Link, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { downloadModalOpen } from './atom';

const Base = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const Background = styled.div`
  height: 64px;
  background: rgba(246, 246, 246, 0.92);
  border-top: 1px solid #dddddd;
  backdrop-filter: blur(4px);
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  & > a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    height: 100%;
    padding-bottom: 12px;
    box-sizing: border-box;
    text-decoration: none;

    & > img {
      margin-bottom: 6px;
      zoom: 0.5;
    }
    & > div {
      font-size: 11px;
      font-weight: 400;
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  height: 100%;
  padding-bottom: 12px;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  & > img {
    margin-bottom: 6px;
    zoom: 0.5;
  }
  & > div {
    font-size: 11px;
    font-weight: 400;
    color: #adb5bd;
  }
`;

const MenuTitle = styled.div<{ isClick: boolean }>`
  color: ${({ isClick }) => (isClick ? '#076dae' : '#404040')};
`;

const BottomMenu = () => {
  const setOpen = useSetRecoilState(downloadModalOpen);
  const [isMarket, setIsMarket] = useState(true);
  const [isReservation, setIsReservation] = useState(false);

  const { pathname } = useLocation();

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    if (pathname === '/') {
      setIsMarket(true);
      setIsReservation(false);
    } else if (pathname === '/reservation') {
      setIsMarket(false);
      setIsReservation(true);
    }
    window.scrollTo({
      top: 0,
    });
  }, [pathname]);

  return (
    <Base>
      <Background>
        <Menu>
          <Link to="/">
            <img src={isMarket ? footer1On : footer1} alt="레빠마켓" />
            <MenuTitle isClick={isMarket}>레빠마켓</MenuTitle>
          </Link>
          <MenuItem onClick={onClick}>
            <img src={footer2} alt="채팅" />
            <div>채팅</div>
          </MenuItem>
          <Link to="/reservation">
            <img src={isReservation ? footer3On : footer3} alt="예약하기" />
            <MenuTitle isClick={isReservation}>예약하기</MenuTitle>
          </Link>
          <MenuItem onClick={onClick}>
            <img src={footer4} alt="마이페이지" />
            <div>마이페이지</div>
          </MenuItem>
        </Menu>
      </Background>
    </Base>
  );
};

export default React.memo(BottomMenu);
