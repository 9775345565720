import SkeletonImage from '../common/SkeletonImage';
import React, { useCallback } from 'react';
import { CompanyDetail } from '../../types/reservation';
import styled from '@emotion/styled';
import Map from './Map';

const Base = styled.div`
  background: #fff;
`;

const DetailDescriptionImages = styled.div`
  display: flex;
  flex-direction: column;
  & > div > img {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 50px;
  background: #fff;
  width: 100%;
  max-width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }

  & > button {
    background: #076dae;
    border-radius: 8px;
    border: 0;
    color: #ffffff;
    padding: 8px 0;
    width: 80%;
    cursor: pointer;
  }
`;

const Top = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  & > div:nth-of-type(1) {
    font-weight: 700;
    font-size: 16px;
    color: #212529;
  }
`;
interface Props {
  data: CompanyDetail;
  setToastShow: (isShow: boolean) => void;
}

const CampingDetail = ({ data, setToastShow }: Props) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      window.open(data.normalProducts[0].description, '_blank');
    },
    [data.normalProducts],
  );

  return (
    <Base>
      <DetailDescriptionImages>
        {data.descriptionFiles.map((v) => (
          <SkeletonImage
            key={v.filePath}
            image={v.filePath}
            alt={'상세'}
            style={{
              height: '300px',
            }}
          />
        ))}
      </DetailDescriptionImages>
      <div style={{ paddingBottom: '50px' }}>
        <Top>
          <div>장소</div>
        </Top>
        <Map
          lon={data.lon}
          lat={data.lat}
          setToastShow={setToastShow}
          address={data.address}
        />
      </div>
      <ButtonWrapper>
        <button onClick={onClick}>예약하기</button>
      </ButtonWrapper>
    </Base>
  );
};

export default CampingDetail;
