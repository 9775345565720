import React, { useCallback } from 'react';
import delButton from '../../assets/reservation/ico_x1.png';

interface Props {
  text: string;
  onDeleteClick: (text: string) => void;
  onSearch: (text: string) => void;
}

const SearchItem = ({ text, onDeleteClick, onSearch }: Props) => {
  const onClick = useCallback(() => {
    onDeleteClick(text);
  }, [onDeleteClick, text]);

  const onDivClick = useCallback(() => {
    onSearch(text);
  }, [onSearch, text]);

  return (
    <li>
      <div onClick={onDivClick}>{text}</div>
      <img src={delButton} onClick={onClick} alt="삭제" />
    </li>
  );
};

export default SearchItem;
