import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import down from '../../assets/common/footer/down.png';
import up from '../../assets/common/footer/up.png';
import youtubeImage from '../../assets/common/footer/Frame.png';
import facebookImage from '../../assets/common/footer/facebook.png';
import instaImage from '../../assets/common/footer/insta.png';
import kakaoImage from '../../assets/common/footer/kakao_talk.png';
import loveegg from '../../assets/common/footer/loveegg.png';

const Base = styled.div`
  background: #f8f8f8;
  padding-bottom: 100px;
`;
const FLexDiv = styled.div`
  display: flex;
  padding: 10px 16px;
`;
const Title = styled.div`
  width: 40%;
  & > img {
    width: calc(100% - 10px);
  }
`;

const ButtonArea = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 10%;
  & > img {
    width: 13px;
    cursor: pointer;
  }
`;

const CompanyInfo = styled.div<{ isDown: boolean }>`
  color: #404040;
  padding-top: 16px;
  font-size: 12px;
  font-weight: 500;
  margin: 0 20px;
  line-height: 20px;
  display: ${({ isDown }) => (isDown ? 'block' : 'none')};

  & > a {
    text-decoration: none;
    color: #404040;
  }
`;

const SnsButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  & > img {
    width: 20px;
    height: 20px;
    padding: 0 12px;
    cursor: pointer;
  }
`;

const LinkDiv = styled.div`
  padding: 24px 24px 16px;
  & > a {
    text-decoration: none;
    color: #404040;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  & > a:not(:last-of-type) {
    padding-bottom: 4px;
  }
`;

const InfoDiv = styled.div`
  padding: 16px;
  font-size: 11px;
  color: #343a40;
  line-height: 16px;
`;

const CopyRightDiv = styled.div`
  font-weight: 500;
  color: #404040;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  justify-content: center;
`;

const Footer = () => {
  const [isDown, setIsDown] = useState(false);
  const onClick = useCallback(() => {
    if (!isDown) {
      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
    setIsDown(!isDown);
  }, [isDown]);

  const facebookClick = useCallback(() => {
    window.open('https://www.facebook.com/leisureholic/', '_blank');
  }, []);

  const instaClick = useCallback(() => {
    window.open('https://www.instagram.com/leisure_holic/', '_blank');
  }, []);

  const youtubeClick = useCallback(() => {
    window.open(
      'https://www.youtube.com/channel/UCNwbtEp5FhA8qfUU-HU5wpw',
      '_blank',
    );
  }, []);

  const kakaoClick = useCallback(() => {
    window.open('https://pf.kakao.com/_KMhRb', '_blank');
  }, []);

  return (
    <Base>
      <LinkDiv>
        <a href="https://www.loveegg.co.kr/sub/terms01.html">이용약관</a>
        <br />
        <a href="https://www.loveegg.co.kr/sub/terms02.html">
          위치기반서비스 이용약관
        </a>
        <br />
        <a href="https://www.loveegg.co.kr/sub/terms03.html">
          중고거래 이용약관
        </a>
        <br />
        <a href="https://www.loveegg.co.kr/sub/privacy.html">
          개인정보처리방침
        </a>
      </LinkDiv>
      <FLexDiv>
        <Title>
          <img src={loveegg} alt={'loveegg'} />{' '}
        </Title>
        <ButtonArea>
          {!isDown && <img src={down} alt="down" onClick={onClick} />}
          {isDown && <img src={up} alt="up" onClick={onClick} />}
        </ButtonArea>
        <SnsButtonArea>
          <img src={facebookImage} alt={'facebook'} onClick={facebookClick} />
          <img src={instaImage} alt={'insta'} onClick={instaClick} />
          <img
            src={youtubeImage}
            alt={'youtube'}
            style={{ width: '25px', height: '18px' }}
            onClick={youtubeClick}
          />
          <img
            src={kakaoImage}
            alt={'kakao'}
            style={{ paddingRight: 0 }}
            onClick={kakaoClick}
          />
        </SnsButtonArea>
      </FLexDiv>
      <CompanyInfo isDown={isDown}>
        (주)러브에그 <br />
        CEO: 정석원 <br />
        주소: 강원도 춘천시 시청길 32, 3층(조양동) <br />
        서울시 성동구 성수일로 10길 26 하우스디세종타워 9F, 907 <br />
        사업자등록번호: 238-81-01864 <br />
        통신판매업번호: 2021-서울성동-02130호 <br />
        TEL: 1533-1184 <br />
        FAX: 02-462-6923 <br />
        E-mail: help@loveegg.co.kr <br />
      </CompanyInfo>
      <InfoDiv>
        (주)러브에그는 통신판매자중개자로서 통신판매의 당사자가 아니며 상품의
        예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
      </InfoDiv>
      <CopyRightDiv>
        Copyright © 2023 LOVEEGG Inc. All Rights Reserved.
      </CopyRightDiv>
    </Base>
  );
};

export default React.memo(Footer);
