import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Market from './pages/Market';
import Reservation from './pages/Reservation';
import AppDownloadModal from './components/common/AppDownloadModal';
import { useRecoilValue } from 'recoil';
import { downloadModalOpen } from './components/common/atom';
import Search from './pages/Search';
import SearchResult from './pages/SearchResult';
import CompanyDetail from './pages/CompanyDetail';
import { createGlobalStyle } from 'styled-components';
import ProductDetail from './pages/MarketDetail';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle` 
${reset}   /* css reset */
`;

function App() {
  const modalOpen = useRecoilValue(downloadModalOpen);
  // const setImageWidthSize = useSetRecoilState(imageWidthSize);
  //
  // useEffect(() => {
  //   const md = new MobileDetect(window.navigator.userAgent);
  //   if (md.tablet()) {
  //     setImageWidthSize({
  //       top: 700,
  //       reservationList: 700,
  //       detail: 800,
  //       productList: 200,
  //     });
  //   }
  // }, [setImageWidthSize]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Market />} />
        <Route path="/market/:itemId" element={<ProductDetail />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/reservation/search" element={<Search />} />
        <Route
          path="/reservation/search-result/:text"
          element={<SearchResult />}
        />
        <Route path="/reservation/:companyId" element={<CompanyDetail />} />
      </Routes>
      <GlobalStyle />
      {modalOpen && <AppDownloadModal />}
    </BrowserRouter>
  );
}

export default App;
