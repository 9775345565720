import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import React, { SetStateAction, useCallback, useState } from 'react';
import Logo from '../common/Logo';
import backButton from '../../assets/reservation/ico_arrow_back.png';
import searchIcon from '../../assets/reservation/ico_search.png';
import searchDelIcon from '../../assets/reservation/ico_search_del.png';

const SearchHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }
`;

const SearchTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: #f1f3f5;
  text-align: center;
  border-bottom: 1px solid #dee2e6;

  & > div {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    color: #343a40;
  }
`;

const BackButton = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
`;

const SearchArea = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 40px;
  padding: 0 15px;
  border: 3px solid #179ac2;
  border-radius: 8px;
  background: #eeeeee;

  & > input {
    width: calc(100% - 14px);
    height: 100%;
    border: none;
    background: none;
    font-weight: 700;
    font-size: 16px;
    color: #404040;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
    }
  }

  & > img {
    width: 18px;
    padding-right: 10px;

    &:nth-last-of-type(1) {
      padding-right: 0;
      padding-left: 10px;
      cursor: pointer;
    }
  }
`;

const SearchAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background: #f1f3f5;
`;

const InputClear = styled.img<{ isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
`;

interface Props {
  keywordList: Array<string>;
  setKeywordList: (arr: SetStateAction<string[]>) => void;
  backPath: string;
  text?: string;
}

const ReservationTopSearchArea = ({
  keywordList,
  setKeywordList,
  backPath,
  text = '',
}: Props) => {
  const [searchText, setSearchText] = useState(text);
  const navigate = useNavigate();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [setSearchText],
  );

  const companySearch = useCallback(
    (text: string) => {
      const trimText = text.trim();
      const searchArr = [
        trimText,
        ...keywordList.filter((v) => v !== trimText),
      ];
      setKeywordList(searchArr);
      localStorage.setItem('search', JSON.stringify(searchArr));
      navigate('/reservation/search-result/' + encodeURIComponent(trimText));
    },
    [keywordList, navigate, setKeywordList],
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && searchText !== '') {
        companySearch(searchText);
      }
    },
    [companySearch, searchText],
  );

  const onBackClick = useCallback(() => {
    navigate(backPath);
  }, [backPath, navigate]);

  const onClearClick = useCallback((e: React.MouseEvent<HTMLImageElement>) => {
    setSearchText('');
  }, []);

  return (
    <SearchHeader>
      <Logo />
      <SearchTitle>
        <BackButton src={backButton} onClick={onBackClick} alt="뒤로가기" />
        <div>검색</div>
      </SearchTitle>
      <SearchAreaWrapper>
        <SearchArea>
          <img src={searchIcon} alt="검색" />
          <input
            type="text"
            value={searchText}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder="지역/레저/프로그램 검색"
          />
          <InputClear
            isShow={searchText.length > 0}
            src={searchDelIcon}
            onClick={onClearClick}
            alt="삭제"
          />
        </SearchArea>
      </SearchAreaWrapper>
    </SearchHeader>
  );
};

export default ReservationTopSearchArea;
