import { atom } from 'recoil';

export const downloadModalOpen = atom({
  key: 'downloadModalOpen',
  default: false,
});

export const imageWidthSize = atom({
  key: 'imageWidthSize',
  default: {
    top: 700,
    reservationList: 700,
    detail: 800,
    productList: 200,
  },
});
