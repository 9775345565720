import styled from '@emotion/styled';
import noProfile from '../../assets/common/default_user.png';
import { ReviewImage } from '../../types/reservation';

const Base = styled.li`
  padding: 20px 0;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 10px;
  }
`;

const WriterInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > img {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

const WriterData = styled.div`
  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #404040;

    & > span {
      margin-left: 7px;
      font-size: 9px;
      font-weight: 400;
      color: #404040;
    }
  }

  & > div:nth-of-type(2) {
    margin-top: 1px;
    font-size: 11px;
    font-weight: 700;
    color: #404040;
    text-align: left;
  }
`;

const ReviewContent = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #404040;
  line-height: 18px;
  text-align: left;
`;

const ReviewTag = styled.div`
  margin-bottom: 15px;
  font-size: 11px;
  font-weight: 400;
  color: #007eca;
  line-height: 18px;
  text-align: left;
`;

const ReviewGallery = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    width: calc(20% - 4px);
    margin-right: 5px;
    & > img {
      border-radius: 5px;
      box-sizing: border-box;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
`;

interface Props {
  profileImagePath: string;
  name: string;
  regDt: string;
  reviewTxt: string;
  message: string;
  boatName: string;
  leisureName: string;
  title: string;
  images: Array<ReviewImage>;
}

const ReviewItem = ({
  reviewTxt,
  message,
  title,
  leisureName,
  name,
  boatName,
  regDt,
  profileImagePath,
  images,
}: Props) => {
  return (
    <Base>
      <WriterInfo>
        <img src={profileImagePath || noProfile} alt="프로필" />
        <WriterData>
          <div>
            {name}
            <span>{regDt} 작성</span>
          </div>
          <div>
            {reviewTxt === 'GOOD' ? '또 가고싶어요' : '또 안가고싶어요'}
          </div>
        </WriterData>
      </WriterInfo>
      <ReviewContent>{message}</ReviewContent>
      <ReviewTag>
        <span>
          {title === '' ? '#' + boatName + ' | ' + leisureName : '#' + title}
        </span>
      </ReviewTag>
      <ReviewGallery>
        {images.map((v) => (
          <li key={v.reviewImageId}>
            <img src={v.reviewImagePath} alt="" />
          </li>
        ))}
      </ReviewGallery>
    </Base>
  );
};

export default ReviewItem;
