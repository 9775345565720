import React from 'react';
import styled from '@emotion/styled';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';

const Base = styled.div`
  & + & {
    padding-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: -3px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-top: 1px solid #e9ecef;
  cursor: pointer;

  & > div > img {
    width: 100%;
    max-height: 203px;
    border-radius: 10px 10px 0 0;
  }
`;

const TextWrapper = styled.div`
  background: #fff;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
`;

const Sgg = styled.div`
  color: #666666;
  font-size: 12px;
  padding: 15px 0 0 15px;
  letter-spacing: -0.2px;
  width: 90%;
`;

const Name = styled.div`
  color: #000000;
  font-size: 16px;
  padding: 5px 0 0 15px;
  letter-spacing: -0.5px;
  width: 90%;
`;

const Tag = styled.div`
  color: #6db1f7;
  font-size: 12px;
  padding: 5px 0 0 15px;
  letter-spacing: -0.2px;
  width: 90%;
`;

const ViewCount = styled.div`
  color: #666666;
  font-size: 12px;
  padding: 5px 0 15px 15px;
  letter-spacing: -0.2px;
  width: 90%;

  & > span {
    color: #1631b8;
  }
`;

const SkeletonCompanyItem = () => {
  return (
    <Base>
      <ImageWrapper>
        <Skeleton
          style={{
            borderRadius: '10px 10px 0 0',
            height: '200px',
            paddingTop: '5px',
          }}
        />
      </ImageWrapper>
      <TextWrapper>
        <Sgg>
          <Skeleton />
        </Sgg>
        <Name>
          <Skeleton />
        </Name>
        <Tag>
          <Skeleton />
        </Tag>
        <ViewCount>
          <Skeleton />
        </ViewCount>
      </TextWrapper>
    </Base>
  );
};

export default SkeletonCompanyItem;
