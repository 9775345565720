import { useEffect } from 'react';
import styled from '@emotion/styled';

interface Props {
  setToast: (isShow: boolean) => void;
  text: string;
}
const Base = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  z-index: 100;
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  width: calc(100% - 40px);
  border: 1px solid #2a2a2a;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6), 0 0 1px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background: #323333;
  animation: toast1 1.5s;
  transition: 0.3s all ease-out;
  max-width: 300px;
  @media only screen and (max-width: 720px) {
    max-width: 80%;
  }

  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    letter-spacing: -0.14px;
  }
`;
const Toast = ({ setToast, text }: Props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setToast(false);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [setToast]);

  return (
    <Base>
      <p>{text}</p>
    </Base>
  );
};
export default Toast;
