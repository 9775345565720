import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from '@emotion/styled';

const Wrapper = styled.div<{ loading: string }>`
  & > img {
    display: ${({ loading }) => (loading === 'true' ? 'none' : 'block')};
  }
`;

interface Props {
  image: string;
  style?: React.CSSProperties | undefined;
  alt: string;
}

const SkeletonImage = ({ image, style, alt }: Props) => {
  const [loading, setLoading] = useState('true');
  return (
    <Wrapper loading={loading}>
      {loading === 'true' && <Skeleton style={style} />}
      <img
        src={image}
        alt={alt}
        onLoad={() => {
          setLoading('false');
        }}
      />
    </Wrapper>
  );
};

export default SkeletonImage;
